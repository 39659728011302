import * as React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { globalHistory } from "@reach/router";
import { Disqus } from "gatsby-plugin-disqus";

import Template from "../components/Template";

import * as styles from "./BlogPost.module.scss";

const BlogPost = ({ data, location }) => {
  const pageLang = !globalHistory.location.pathname.includes("/fr")
    ? "en"
    : "fr";
  const show = (lang) => {
    if (lang === "en" && pageLang === "en") return { display: "block" };
    else if (lang === "fr" && pageLang === "fr") return { display: "block" };
    else return { display: "none" };
  };

  const { markdownRemark } = data; // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark;
  console.log(frontmatter);
  const date = new Date(Date.parse(frontmatter.date));
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <Template location={location}>
      <Helmet>
        <title>{`Charih / ${pageLang === "en" ? "Blog" : "Blog"}`}</title>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        ></link>
      </Helmet>
      <div className={styles.container}>
        <h1>{frontmatter.title}</h1>
        <h2 className={styles.date} style={show("en")}>
          Publication date: {date.toLocaleString("en-US", options)}
        </h2>
        <h2 className={styles.date} style={show("fr")}>
          Date de publication: {date.toLocaleString("fr-CA", options)}
        </h2>
        <div
          className={styles.blogPostContent}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div style={{ padding: "10px" }}>
        <Disqus
          config={{
            url: `https://charih.ca/${pageLang}${frontmatter.slug}`,
            identifier: frontmatter.slug,
            title: frontmatter.title,
          }}
        />
      </div>
    </Template>
  );
};

export const pageQuery = graphql`
  query ($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD", locale: "fr")
        slug
        title
      }
    }
  }
`;

export default BlogPost;
